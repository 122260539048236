import { FunctionComponent } from "react";
import styles from "./Lists.module.css";
const Lists: FunctionComponent = () => {
  return (
    <div className={styles.lists}>
      <div className={styles.header}>
        <img className={styles.logoIcon} alt="" src="/logo13.svg" />
        <div className={styles.title}>
          <div className={styles.componentName}>Lists</div>
          <a
            className={styles.link}
            href="https://m3.material.io/components/lists/overview"
            target="_blank"
          >
            See design guideline
          </a>
          <div className={styles.definition}>
            Lists are continuous, vertical indexes of text and images.
          </div>
        </div>
      </div>
      <div className={styles.components}>
        <div className={styles.lightScheme}>
          <div className={styles.lists1}>
            <div className={styles.list}>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement}>
                    <div className={styles.buildingBlocksmonogram}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
            </div>
            <div className={styles.list4Density}>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1}>
                        <div className={styles.container} />
                        <img
                          className={styles.iconscheckSmall}
                          alt=""
                          src="/iconscheck-small.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buildingBlocksTitle}>
            <div className={styles.label}>List Items</div>
          </div>
          <div className={styles.listItemlistItem0Density}>
            <div className={styles.condition1LineLeadingswit}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer134}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer134}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline160}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer134}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline263}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
          </div>
          <div className={styles.listItemlistItem2Densit}>
            <div className={styles.condition2LineLeadingswit4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer470}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer470}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon1}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon2}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon3}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec4}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer470}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag5}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline263}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
          </div>
          <div className={styles.listItemlistItem2Densit}>
            <div className={styles.condition2LineLeadingswit8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer785}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer785}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec6}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec7}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer785}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement129}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement133}>
                  <div className={styles.buildingBlocksmonogram}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline263}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon32.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1}>
                      <div className={styles.container} />
                      <img
                        className={styles.iconscheckSmall}
                        alt=""
                        src="/iconscheck-small.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider} />
              </div>
            </div>
          </div>
          <div className={styles.buildingBlocks}>
            <div className={styles.label1}>
              <div className={styles.label}>Building Blocks</div>
            </div>
            <div className={styles.buildingBlocks1}>
              <div className={styles.buildingBlocksmonogram}>
                <div className={styles.initial}>A</div>
              </div>
              <div className={styles.stateLayerColors}>
                <div className={styles.buildingBlocksstateLayer2}>
                  <div className={styles.buildingBlocksstateLayer2Child} />
                </div>
                <div className={styles.buildingBlocksstateLayer1} />
                <div className={styles.buildingBlocksstateLayer3}>
                  <div className={styles.buildingBlocksstateLayer3Child} />
                </div>
                <div className={styles.buildingBlocksstateLayer4}>
                  <div className={styles.buildingBlocksstateLayer2Child} />
                  <img
                    className={styles.rippleIcon}
                    alt=""
                    src="/ripple2.svg"
                  />
                </div>
                <div className={styles.buildingBlocksstateLayer5}>
                  <div className={styles.buildingBlocksstateLayer5Child} />
                </div>
              </div>
            </div>
            <div className={styles.sharedBuildingBlocks}>
              <div className={styles.buildingBlocksimageThumbnai}>
                <img
                  className={styles.thumbnailIcon20}
                  alt=""
                  src="/thumbnail2@2x.png"
                />
              </div>
              <div className={styles.buildingBlocksvideoThumbnai}>
                <img
                  className={styles.thumbnailIcon}
                  alt=""
                  src="/thumbnail3@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.darkScheme}>
          <div className={styles.listsDark}>
            <div className={styles.listDark}>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
            </div>
            <div className={styles.listDark}>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer24}>
                  <div className={styles.leadingElement54}>
                    <div className={styles.buildingBlocksmonogramDark}>
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                  <div className={styles.content688}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur
                    </div>
                  </div>
                  <div className={styles.trailingElement530}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
            </div>
            <div className={styles.list4DensityDark}>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
              <div className={styles.listItem1}>
                <div className={styles.stateLayerOverlay} />
                <div className={styles.stateLayer48}>
                  <div className={styles.content24}>
                    <div className={styles.overline}>Overline</div>
                    <div className={styles.headline688}>List item</div>
                    <div className={styles.supportingText}>
                      Supporting line text lorem ipsum dolor sit amet,
                      consectetur.
                    </div>
                  </div>
                  <div className={styles.trailingElement518}>
                    <div className={styles.metadata}>100+</div>
                    <div className={styles.checkboxes}>
                      <div className={styles.stateLayer1021}>
                        <div className={styles.container332} />
                        <img
                          className={styles.iconscheckSmall184}
                          alt=""
                          src="/iconscheck-small1.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalfullWidth}>
                  <div className={styles.divider1340} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buildingBlocksTitleEmpty}>
            <div className={styles.label}>List Items</div>
          </div>
          <div className={styles.listItemlistItem0Density1}>
            <div className={styles.condition1LineLeadingswit8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec8}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec9}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid60}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid61}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid62}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid63}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer134}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid64}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid65}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid66}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid67}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid68}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid69}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer134}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid70}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid71}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid72}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid73}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid74}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima60}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima61}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima62}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima63}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima64}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima65}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima66}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima67}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima68}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima69}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima70}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima71}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima72}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima73}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima74}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico60}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico61}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico62}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico63}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico64}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico65}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico66}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico67}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico68}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico69}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico70}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico71}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico72}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico73}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico74}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon60}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon61}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon62}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon63}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon64}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon65}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon66}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon67}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon68}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon69}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon70}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon71}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon72}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon73}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon74}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline160}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon60}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon61}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon62}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon63}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon64}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon65}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon66}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon67}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon68}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon69}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon70}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon71}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon72}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon73}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon74}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer122}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer129}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer134}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag10}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag11}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer72}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline263}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer73}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer76}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer268}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
          </div>
          <div className={styles.listItemlistItem2Densit1}>
            <div className={styles.condition2LineLeadingswit16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid80}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid81}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid82}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid83}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer470}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid84}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid85}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid86}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid87}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid88}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid89}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer470}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid90}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid91}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid92}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid93}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid94}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima80}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima81}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima82}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima83}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima84}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima85}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima86}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima87}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima88}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima89}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima90}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima91}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima92}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima93}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima94}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico80}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico81}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico82}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico83}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico84}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico85}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico86}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico87}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico88}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico89}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico90}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico91}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico92}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico93}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico94}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon80}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon81}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon82}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon83}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon84}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon85}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon86}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon87}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon88}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon89}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon90}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon91}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon92}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon93}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon94}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon80}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon81}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon82}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon83}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon84}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon85}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon86}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon87}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon88}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon89}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon90}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon91}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon92}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon93}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon94}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec12}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec13}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide24}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer458}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer465}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer470}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag16}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag17}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag18}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingimag19}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingimag39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingmono29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1046}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon95}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon96}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon97}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon98}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon99}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content1051}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone30}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone31}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone32}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone33}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer418}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone34}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline263}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone48}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement730}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone49}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer419}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon75}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon76}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon77}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon78}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer420}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingElement810}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon79}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer583}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet, consectetur
                  </div>
                </div>
                <div className={styles.trailingSupportingText232}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
          </div>
          <div className={styles.listItemlistItem4Densit1}>
            <div className={styles.condition2LineLeadingswit20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec20}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec21}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche40}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche41}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche42}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche43}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche44}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche45}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid100}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid101}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid102}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid103}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer785}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid104}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid105}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid106}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid107}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid108}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid109}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer785}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid110}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid111}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid112}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid113}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid114}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima100}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima101}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima102}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima103}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima104}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima105}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima106}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima107}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima108}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima109}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima110}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima111}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima112}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima113}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima114}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico100}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico101}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico102}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico103}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico104}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico105}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico106}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico107}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico108}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico109}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico110}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico111}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico112}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico113}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico114}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon100}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon101}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon102}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon103}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon104}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon105}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon106}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon107}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon108}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon109}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon110}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon111}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon112}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon113}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon114}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone50}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone51}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone52}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone53}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone54}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon100}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon101}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon102}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon103}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon104}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon105}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon106}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon107}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon108}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon109}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline36}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon110}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon111}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon112}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon113}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon114}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingswit15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingswit23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingswi47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement30}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingradi15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingradi23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingrad47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec14}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingchec15}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec22}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingchec23}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement34}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche46}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingche47}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide25}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide26}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide27}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide28}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingvide29}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingvide59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer773}>
                <div className={styles.leadingElement54}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid115}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid116}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid117}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid118}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer780}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingvid119}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer785}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksvideoThumbnai}>
                    <img
                      className={styles.thumbnailIcon}
                      alt=""
                      src="/thumbnail@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima115}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima116}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima117}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima118}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement59}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingima119}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement84}>
                  <div className={styles.buildingBlocksimageThumbnai}>
                    <img
                      className={styles.thumbnailIcon20}
                      alt=""
                      src="/thumbnail1@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingicon39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingicon59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.leadingElement34}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico115}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico116}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico117}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico118}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingico119}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement40}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsperson-outline-24px1.svg"
                  />
                </div>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingmono59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.leadingElement685}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon115}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon116}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon117}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon118}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement1054}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingmon119}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.leadingElement695}>
                  <div className={styles.buildingBlocksmonogramDark}>
                    <div className={styles.initial}>A</div>
                  </div>
                </div>
                <div className={styles.content141}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone35}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone36}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone37}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone38}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer733}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition1LineLeadingnone39}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline263}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone55}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone56}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksvideoThumbnai}>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone57}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.buildingBlocksimageThumbnai}>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone58}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition2LineLeadingnone59}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer734}>
                <div className={styles.content24}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText183}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon115}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.container36}>
                      <div className={styles.stateLayer83}>
                        <img
                          className={styles.iconsarrowRight24px}
                          alt=""
                          src="/icon37.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon116}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content166}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.switch}>
                    <div className={styles.track148} />
                    <div className={styles.handleContainer}>
                      <div className={styles.handle148} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon117}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <img
                    className={styles.iconsarrowRight24px}
                    alt=""
                    src="/iconsarrow-right-24px1.svg"
                  />
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon118}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer735}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingElement131}>
                  <div className={styles.metadata}>100+</div>
                  <div className={styles.checkboxes}>
                    <div className={styles.stateLayer1021}>
                      <div className={styles.container332} />
                      <img
                        className={styles.iconscheckSmall184}
                        alt=""
                        src="/iconscheck-small1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
            <div className={styles.condition3LineLeadingnon119}>
              <div className={styles.stateLayerOverlay} />
              <div className={styles.stateLayer891}>
                <div className={styles.content40}>
                  <div className={styles.overline}>Overline</div>
                  <div className={styles.headline688}>List item</div>
                  <div className={styles.supportingText40}>
                    Supporting line text lorem ipsum dolor sit amet,
                    consectetur.
                  </div>
                </div>
                <div className={styles.trailingSupportingText}>100+</div>
                <div className={styles.minHeightFix12} />
              </div>
              <div className={styles.horizontalfullWidth}>
                <div className={styles.divider1340} />
              </div>
            </div>
          </div>
          <div className={styles.buildingBlocks2}>
            <div className={styles.label}>Building Blocks</div>
            <div className={styles.buildingBlocks3}>
              <div className={styles.buildingBlocksmonogramDark}>
                <div className={styles.initial}>A</div>
              </div>
              <div className={styles.stateLayerColorsDark}>
                <div className={styles.buildingBlocksstateLayerDa}>
                  <div className={styles.buildingBlocksstateLayerDaChild} />
                </div>
                <div className={styles.buildingBlocksstateLayerDa1} />
                <div className={styles.buildingBlocksstateLayerDa2}>
                  <div className={styles.buildingBlocksstateLayerDaItem} />
                </div>
                <div className={styles.buildingBlocksstateLayerDa3}>
                  <div className={styles.buildingBlocksstateLayerDaChild} />
                  <img
                    className={styles.rippleIcon}
                    alt=""
                    src="/ripple3.svg"
                  />
                </div>
                <div className={styles.buildingBlocksstateLayerDa4}>
                  <div className={styles.rectangleDiv} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lists;
