import { FunctionComponent } from "react";
import styles from "./Search.module.css";
const Search: FunctionComponent = () => {
  return (
    <div className={styles.search}>
      <div className={styles.header}>
        <img className={styles.logoIcon} alt="" src="/logo6.svg" />
        <div className={styles.title}>
          <div className={styles.componentName}>Search</div>
          <a
            className={styles.link}
            href="https://m3.material.io/components/search/overview"
            target="_blank"
          >
            See design guideline
          </a>
          <div className={styles.definition}>
            Search allows users to enter a keyword or phrase and get relevant
            information. It’s an alternative to other forms of navigation.
          </div>
        </div>
      </div>
      <div className={styles.components}>
        <div className={styles.lightScheme}>
          <div className={styles.label}>
            <div className={styles.label1}>search bar</div>
          </div>
          <div className={styles.searchBar}>
            <div className={styles.statepressedShowAvatartru}>
              <div className={styles.stateLayer}>
                <img className={styles.rippleIcon} alt="" src="/ripple.svg" />
                <div className={styles.leadingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/iconsmenu-24px.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.avatarTarget}>
                    <div className={styles.avatar}>
                      <div className={styles.background} />
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statehoveredShowAvatartru}>
              <div className={styles.stateLayer3}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/iconsmenu-24px.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements1}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.avatarTarget}>
                    <div className={styles.avatar}>
                      <div className={styles.background} />
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.stateenabledShowAvatartru}>
              <div className={styles.stateLayer6}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/iconsmenu-24px.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements1}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.avatarTarget}>
                    <div className={styles.avatar}>
                      <div className={styles.background} />
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statepressedShowAvatarfal}>
              <div className={styles.stateLayer}>
                <img className={styles.rippleIcon} alt="" src="/ripple.svg" />
                <div className={styles.leadingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/iconsmenu-24px.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements3}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ndTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsmore-vert-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statehoveredShowAvatarfal}>
              <div className={styles.stateLayer3}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/iconsmenu-24px.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements4}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ndTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsmore-vert-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.stateenabledShowAvatarfal}>
              <div className={styles.stateLayer6}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/iconsmenu-24px.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements4}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ndTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsmore-vert-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.searchViewModal}>
            <div className={styles.label2}>
              <div className={styles.label1}>search view modal</div>
            </div>
            <div className={styles.searchViewFullScreen}>
              <div className={styles.labelTextinputTextShowLi}>
                <div className={styles.header1}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content6}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px3.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextsupportingTextSh}>
                <div className={styles.header2}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content1}>
                    <div className={styles.supportingText}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextinputTextShowLi}>
                <div className={styles.header1}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content6}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px3.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list2}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.labelTextsupportingTextSh}>
                <div className={styles.header2}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content1}>
                    <div className={styles.supportingText12}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list3}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.searchViewModal}>
            <div className={styles.label2}>
              <div className={styles.label1}>search view full screen</div>
            </div>
            <div className={styles.searchViewFullScreen}>
              <div className={styles.labelTextinputTextListIt}>
                <div className={styles.header5}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content20}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px3.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextsupportingTextLi}>
                <div className={styles.header6}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content21}>
                    <div className={styles.supportingText}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextinputTextListIt}>
                <div className={styles.header5}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content20}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px3.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list2}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.labelTextsupportingTextLi}>
                <div className={styles.header6}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content21}>
                    <div className={styles.supportingText24}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider1} />
                </div>
                <div className={styles.list3}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement}>
                        <div className={styles.buildingBlocksmonogram}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider7} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.darkScheme}>
          <div className={styles.label}>
            <div className={styles.label1}>search bar</div>
          </div>
          <div className={styles.searchBarDark}>
            <div className={styles.statepressedShowAvatartru1}>
              <div className={styles.stateLayer53}>
                <img className={styles.rippleIcon} alt="" src="/ripple1.svg" />
                <div className={styles.leadingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img className={styles.icon} alt="" src="/icon25.svg" />
                    </div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements6}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon26.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.avatarTarget}>
                    <div className={styles.avatar}>
                      <div className={styles.background3} />
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statehoveredShowAvatartru1}>
              <div className={styles.stateLayer56}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img className={styles.icon} alt="" src="/icon25.svg" />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements7}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon26.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.avatarTarget}>
                    <div className={styles.avatar}>
                      <div className={styles.background3} />
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.stateenabledShowAvatartru1}>
              <div className={styles.stateLayer6}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img className={styles.icon} alt="" src="/icon25.svg" />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements7}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon26.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.avatarTarget}>
                    <div className={styles.avatar}>
                      <div className={styles.background3} />
                      <div className={styles.initial}>A</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statepressedShowAvatarfal1}>
              <div className={styles.stateLayer53}>
                <img className={styles.rippleIcon} alt="" src="/ripple1.svg" />
                <div className={styles.leadingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img className={styles.icon} alt="" src="/icon25.svg" />
                    </div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements3}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon26.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ndTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon27.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statehoveredShowAvatarfal1}>
              <div className={styles.stateLayer56}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img className={styles.icon} alt="" src="/icon25.svg" />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements4}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon26.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ndTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon27.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.stateenabledShowAvatarfal1}>
              <div className={styles.stateLayer6}>
                <div className={styles.stTrailingIcon}>
                  <div className={styles.container}>
                    <div className={styles.stateLayer1}>
                      <img className={styles.icon} alt="" src="/icon25.svg" />
                    </div>
                  </div>
                </div>
                <div className={styles.content1}>
                  <div className={styles.supportingText}>
                    Hinted search text
                  </div>
                </div>
                <div className={styles.trailingElements4}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon26.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.ndTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon27.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.searchViewModal}>
            <div className={styles.label2}>
              <div className={styles.label1}>search view modal</div>
            </div>
            <div className={styles.searchViewModalDark}>
              <div className={styles.labelTextinputTextShowLi2}>
                <div className={styles.header9}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content6}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px4.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextsupportingTextSh2}>
                <div className={styles.header10}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content1}>
                    <div className={styles.supportingText}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextinputTextShowLi2}>
                <div className={styles.header9}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content6}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px4.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list10}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.labelTextsupportingTextSh2}>
                <div className={styles.header10}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content1}>
                    <div className={styles.supportingText12}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list3}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.searchViewModal}>
            <div className={styles.label2}>
              <div className={styles.label1}>search view full screen</div>
            </div>
            <div className={styles.searchViewModalDark}>
              <div className={styles.labelTextinputTextListIt2}>
                <div className={styles.header13}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content20}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px4.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextsupportingTextLi2}>
                <div className={styles.header14}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content21}>
                    <div className={styles.supportingText}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list} />
              </div>
              <div className={styles.labelTextinputTextListIt2}>
                <div className={styles.header13}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content20}>
                    <div className={styles.inputText}>
                      <div className={styles.supportingText}>Input text</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsclose-24px4.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list10}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.labelTextsupportingTextLi2}>
                <div className={styles.header14}>
                  <div className={styles.stTrailingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img
                          className={styles.icon}
                          alt=""
                          src="/iconsarrow-back-24px1.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content21}>
                    <div className={styles.supportingText24}>
                      Hinted search text
                    </div>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div className={styles.divider52} />
                </div>
                <div className={styles.list3}>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem1}>
                    <div className={styles.stateLayerOverlay10} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                  <div className={styles.listItem4}>
                    <div className={styles.stateLayerOverlay3} />
                    <div className={styles.stateLayer26}>
                      <div className={styles.leadingElement20}>
                        <div className={styles.buildingBlocksmonogramDark}>
                          <div className={styles.initial}>A</div>
                        </div>
                      </div>
                      <div className={styles.content9}>
                        <div className={styles.overline}>Overline</div>
                        <div className={styles.headline20}>List item</div>
                        <div className={styles.supportingText7}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider6}>
                      <div className={styles.divider56} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
