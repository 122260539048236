import { FunctionComponent } from "react";
import styles from "./TextFields.module.css";
const TextFields: FunctionComponent = () => {
  return (
    <div className={styles.textFields}>
      <div className={styles.header}>
        <img className={styles.logoIcon} alt="" src="/logo.svg" />
        <div className={styles.title}>
          <div className={styles.componentName}>Text Fields</div>
          <a
            className={styles.link}
            href="https://m3.material.io/components/text-fields/overview"
            target="_blank"
          >
            See design guideline
          </a>
          <div className={styles.definition}>
            Text fields allow users to enter text into a UI. They typically
            appear in forms and dialogs.
          </div>
        </div>
      </div>
      <div className={styles.components}>
        <div className={styles.lightScheme}>
          <div className={styles.textField}>
            <div className={styles.styleoutlinedStatedisabled}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor} />
            </div>
            <div className={styles.styleoutlinedStateerrorT}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText2}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText4}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText6}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled1}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT1}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered1}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused1}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText6}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled1}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled2}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer20}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT2}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                    <div className={styles.labelText2}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered2}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused2}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText6}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled2}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText28}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled3}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer30}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor} />
            </div>
            <div className={styles.styleoutlinedStateerrorT3}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText32}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered3}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused3}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText36}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled3}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled4}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer30}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT4}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered4}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused4}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText36}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled4}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled5}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer40}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT5}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                    <div className={styles.labelText32}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered5}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused5}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText36}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled5}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText58}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled6}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer45}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor} />
            </div>
            <div className={styles.styleoutlinedStateerrorT6}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText2}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered6}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText4}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused6}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText6}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled6}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled7}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer45}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT7}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered7}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused7}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText6}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled7}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled8}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer75}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT8}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                    <div className={styles.labelText2}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered8}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused8}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText6}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled8}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText28}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled9}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer90}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor} />
            </div>
            <div className={styles.styleoutlinedStateerrorT9}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText32}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered9}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText94}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused9}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText36}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled9}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled10}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer90}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT10}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered10}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused10}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText36}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled10}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled11}>
              <div className={styles.disabledSateColor1} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer110}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT11}>
              <div className={styles.textField2}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                    <div className={styles.labelText32}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText2}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered11}>
              <div className={styles.textField3}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText30}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused11}>
              <div className={styles.textField4}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                    <div className={styles.labelText36}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled11}>
              <div className={styles.textField5}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText58}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText14}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer124}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled1}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex1}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT1}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer124}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT1}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText48}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT1}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled2}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex2}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT2}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer124}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT2}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT2}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled3}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex3}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT3}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer152}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT3}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT3}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled4}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex4}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT4}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer152}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT4}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText48}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT4}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled5}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex5}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT5}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer152}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT5}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT5}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled6}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex6}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT6}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer171}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT6}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT6}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled7}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex7}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT7}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer171}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT7}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText48}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT7}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled8}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex8}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT8}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer171}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT8}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT8}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon1.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled9}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex9}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT9}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer214}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT9}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText42}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT9}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled10}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex10}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT10}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer214}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT10}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText48}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT10}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled11}>
              <div className={styles.disabledSateColor12} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon2.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex11}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret1.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator1} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT11}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer214}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator2} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT11}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator3} />
              <div className={styles.supportingText126}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT11}>
              <div className={styles.textField62}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText52}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon4.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator4} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.darkScheme}>
          <div className={styles.textFieldDark}>
            <div className={styles.styleoutlinedStatedisabled}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer240}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor24} />
            </div>
            <div className={styles.styleoutlinedStateerrorT12}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText242}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered12}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText244}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused12}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText246}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled12}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled1}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer240}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT13}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered1}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused1}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText246}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled13}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled2}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer260}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT2}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                    <div className={styles.labelText242}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered2}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused2}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText246}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled2}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText268}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled3}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer270}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor24} />
            </div>
            <div className={styles.styleoutlinedStateerrorT15}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText272}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered15}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText274}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused15}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText276}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled15}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled4}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer270}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT16}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered4}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused4}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText276}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled16}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled5}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer280}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT5}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                    <div className={styles.labelText272}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered5}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused5}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText276}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled5}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText298}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled6}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer285}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor24} />
            </div>
            <div className={styles.styleoutlinedStateerrorT18}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText242}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered18}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText244}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused18}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText246}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled18}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled7}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer285}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT19}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered7}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused7}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText246}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled19}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled8}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer315}>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT8}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                    <div className={styles.labelText242}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered8}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText240}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused8}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText246}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled8}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText268}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled9}>
              <div className={styles.textField1}>
                <div className={styles.stateLayer330}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText1}>Placeholder</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
              <div className={styles.disabledSateColor24} />
            </div>
            <div className={styles.styleoutlinedStateerrorT21}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText272}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered21}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText274}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused21}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText276}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled21}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.placeholderText}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled10}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer330}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT22}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered10}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused10}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText276}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled22}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatedisabled11}>
              <div className={styles.disabledSateColor25} />
              <div className={styles.textField6}>
                <div className={styles.stateLayer350}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText1}>Input</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText1}>Label</div>
                    </div>
                  </div>
                  <div className={styles.leadingIcon}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText10}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateerrorT11}>
              <div className={styles.textField122}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                    <div className={styles.labelText272}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText242}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatehovered11}>
              <div className={styles.textField123}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText270}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStatefocused11}>
              <div className={styles.textField124}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                    <div className={styles.labelText276}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.styleoutlinedStateenabled11}>
              <div className={styles.textField125}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content}>
                    <div className={styles.inputText}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                    <div className={styles.labelText298}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.supportingText254}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex12}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT12}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer364}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT12}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT12}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled1}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex13}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT13}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer364}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT13}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText144}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT13}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled2}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex14}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT14}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer364}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT14}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT14}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer2}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled3}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex15}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT15}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer392}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT15}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT15}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled4}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex16}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT16}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer392}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT16}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText156}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT16}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled5}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer31}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex17}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT17}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer392}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT17}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT17}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer151}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled6}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex18}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT18}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer411}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT18}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT18}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled7}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex19}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT19}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer411}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT19}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText144}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT19}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled8}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon5.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex20}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT20}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer411}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT20}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT20}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer48}>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon6.svg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled9}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex21}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT21}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer454}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT21}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText122}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT21}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.placeholderText40}>
                      <div className={styles.placeholderText3}>Placeholder</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled10}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText130}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex22}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT22}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer454}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT22}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText144}>
                      <div className={styles.inputText1}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT22}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.placeholderText3}>Label</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText4}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatedisabled11}>
              <div className={styles.disabledSateColor36} />
              <div className={styles.textField61}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText50}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator60} />
              <div className={styles.supportingText120}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateerrorTex23}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret3.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon8.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator61} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatehoveredT23}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer454}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator62} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStatefocusedT23}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                      <img
                        className={styles.caretIcon}
                        alt=""
                        src="/caret2.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator63} />
              <div className={styles.supportingText366}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
            <div className={styles.stylefilledStateenabledT23}>
              <div className={styles.textField182}>
                <div className={styles.stateLayer92}>
                  <div className={styles.content5}>
                    <div className={styles.labelText10}>
                      <div className={styles.labelText3}>Label</div>
                    </div>
                    <div className={styles.inputText148}>
                      <div className={styles.placeholderText3}>Input</div>
                    </div>
                  </div>
                  <div className={styles.trailingIconError}>
                    <div className={styles.container}>
                      <div className={styles.stateLayer1}>
                        <img className={styles.icon} alt="" src="/icon7.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.activeIndicator64} />
              <div className={styles.supportingText122}>
                <div className={styles.supportingText1}>Supporting text</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextFields;
