import { FunctionComponent } from "react";
import styles from "./Menu.module.css";
const Menu: FunctionComponent = () => {
  return (
    <div className={styles.menu}>
      <div className={styles.header}>
        <img className={styles.logoIcon} alt="" src="/logo12.svg" />
        <div className={styles.title}>
          <div className={styles.componentName}>Menus</div>
          <a
            className={styles.link}
            href="https://m3.material.io/components/menus/overview"
            target="_blank"
          >
            See design guideline
          </a>
          <div className={styles.definition}>
            <p className={styles.menusDisplayA}>
              Menus display a list of choices on a temporary surface. They
              appear when users interact with a button, action, or other
              control.  
            </p>
            <p className={styles.menusDisplayA}>
              For Android the target minimum is always 48dp minimum.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.component}>
        <div className={styles.lightScheme}>
          <div className={styles.menu1}>
            <div className={styles.menu2}>
              <div className={styles.density0}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer9}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar1} />
                </div>
              </div>
              <div className={styles.density2}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer21}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.content12}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar2}>
                  <div className={styles.scrollbar3} />
                </div>
              </div>
              <div className={styles.density4}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer33}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar5} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.examples}>
            <div className={styles.examplesmenuWithTextField}>
              <div className={styles.textField}>
                <div className={styles.textField1}>
                  <div className={styles.stateLayer36}>
                    <div className={styles.content36}>
                      <div className={styles.inputText}>
                        <div className={styles.inputText1}>Input</div>
                        <img
                          className={styles.caretIcon}
                          alt=""
                          src="/caret.svg"
                        />
                      </div>
                      <div className={styles.labelText36}>
                        <div className={styles.labelText37}>Label</div>
                      </div>
                    </div>
                    <div className={styles.trailingIcon}>
                      <div className={styles.container}>
                        <div className={styles.stateLayer37}>
                          <img
                            className={styles.icon}
                            alt=""
                            src="/icon4.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.supportingText36}>
                  <div className={styles.supportingText37}>Supporting text</div>
                </div>
              </div>
              <div className={styles.menu3}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer9}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar1} />
                </div>
              </div>
            </div>
            <div className={styles.examplesmenuWithTextField1}>
              <div className={styles.textField}>
                <div className={styles.textField1}>
                  <div className={styles.stateLayer50}>
                    <div className={styles.trailingIcon}>
                      <div className={styles.container}>
                        <div className={styles.stateLayer37}>
                          <img
                            className={styles.icon}
                            alt=""
                            src="/icon1.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.content36}>
                      <div className={styles.inputText}>
                        <div className={styles.inputText1}>Input</div>
                        <img
                          className={styles.caretIcon}
                          alt=""
                          src="/caret.svg"
                        />
                      </div>
                      <div className={styles.labelText50}>
                        <div className={styles.labelText37}>Label</div>
                      </div>
                    </div>
                    <div className={styles.trailingIcon}>
                      <div className={styles.container}>
                        <div className={styles.stateLayer37}>
                          <img
                            className={styles.icon}
                            alt=""
                            src="/icon4.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.supportingText36}>
                  <div className={styles.supportingText37}>Supporting text</div>
                </div>
              </div>
              <div className={styles.menu3}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer9}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar1} />
                </div>
              </div>
            </div>
            <div className={styles.examplesmenuIconButtonEx}>
              <div className={styles.trailingIcon}>
                <div className={styles.container3}>
                  <div className={styles.stateLayer65}>
                    <img className={styles.icon} alt="" src="/icon42.svg" />
                  </div>
                </div>
              </div>
              <div className={styles.menu3}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer9}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar1} />
                </div>
              </div>
            </div>
            <div className={styles.label}>Component examples</div>
          </div>
          <div className={styles.buildingBlocks}>
            <div className={styles.label1}>Building Blocks</div>
            <div className={styles.container4}>
              <div className={styles.buildingBlocksmenuListItem}>
                <div className={styles.stateenabled}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.stateselected}>
                  <div className={styles.stateLayer9}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.statedisabled}>
                  <div className={styles.stateLayer80}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.statehovered}>
                  <div className={styles.stateLayer81}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
              </div>
              <div className={styles.buildingBlocksmenuListItem}>
                <div className={styles.stateenabled}>
                  <div className={styles.stateLayer12}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.content12}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.stateselected}>
                  <div className={styles.stateLayer21}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.statedisabled}>
                  <div className={styles.stateLayer84}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.statehovered}>
                  <div className={styles.stateLayer85}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
              </div>
              <div className={styles.buildingBlocksmenuListItem}>
                <div className={styles.stateenabled}>
                  <div className={styles.stateLayer24}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.stateselected}>
                  <div className={styles.stateLayer33}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.statedisabled}>
                  <div className={styles.stateLayer88}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.statehovered}>
                  <div className={styles.stateLayer89}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider1} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.elements}>
              <div className={styles.buildingBlocksleadingElemen4}>
                <img className={styles.typeicon} alt="" src="/typeicon.svg" />
                <div className={styles.typeindent} />
              </div>
              <div className={styles.buildingBlockstrailingEleme}>
                <img className={styles.typeicon} alt="" src="/typeicon1.svg" />
                <div className={styles.typetext}>
                  <div className={styles.trailingText}>⌘C</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.darkScheme}>
          <div className={styles.menuDark}>
            <div className={styles.menuDark1}>
              <div className={styles.density01}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer99}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar13} />
                </div>
              </div>
              <div className={styles.density21}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer111}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer12}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar2}>
                  <div className={styles.scrollbar15} />
                </div>
              </div>
              <div className={styles.density41}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer123}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer24}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar2}>
                  <div className={styles.scrollbar17} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.examplesDark}>
            <div className={styles.examplesmenuWithTextField}>
              <div className={styles.textField}>
                <div className={styles.textField4}>
                  <div className={styles.stateLayer36}>
                    <div className={styles.content36}>
                      <div className={styles.inputText}>
                        <div className={styles.inputText1}>Input</div>
                        <img
                          className={styles.caretIcon}
                          alt=""
                          src="/caret2.svg"
                        />
                      </div>
                      <div className={styles.labelText124}>
                        <div className={styles.labelText37}>Label</div>
                      </div>
                    </div>
                    <div className={styles.trailingIcon}>
                      <div className={styles.container}>
                        <div className={styles.stateLayer37}>
                          <img
                            className={styles.icon}
                            alt=""
                            src="/icon7.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.supportingText124}>
                  <div className={styles.supportingText37}>Supporting text</div>
                </div>
              </div>
              <div className={styles.menuDark2}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer99}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar13} />
                </div>
              </div>
            </div>
            <div className={styles.examplesmenuWithTextField1}>
              <div className={styles.textField}>
                <div className={styles.textField4}>
                  <div className={styles.stateLayer50}>
                    <div className={styles.trailingIcon}>
                      <div className={styles.container}>
                        <div className={styles.stateLayer37}>
                          <img
                            className={styles.icon}
                            alt=""
                            src="/icon6.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.content36}>
                      <div className={styles.inputText}>
                        <div className={styles.inputText1}>Input</div>
                        <img
                          className={styles.caretIcon}
                          alt=""
                          src="/caret2.svg"
                        />
                      </div>
                      <div className={styles.labelText138}>
                        <div className={styles.labelText37}>Label</div>
                      </div>
                    </div>
                    <div className={styles.trailingIcon}>
                      <div className={styles.container}>
                        <div className={styles.stateLayer37}>
                          <img
                            className={styles.icon}
                            alt=""
                            src="/icon7.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.supportingText124}>
                  <div className={styles.supportingText37}>Supporting text</div>
                </div>
              </div>
              <div className={styles.menuDark2}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <div className={styles.buildingBlocksleadingElemen} />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer99}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.icon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar13} />
                </div>
              </div>
            </div>
            <div className={styles.examplesmenuIconButtonEx}>
              <div className={styles.iconButtonDark}>
                <div className={styles.container8}>
                  <div className={styles.stateLayer155}>
                    <img className={styles.icon} alt="" src="/icon43.svg" />
                  </div>
                </div>
              </div>
              <div className={styles.menuDark2}>
                <div className={styles.menuList}>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem1}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer99}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                  <div className={styles.menuListItem53}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/leading-element1.svg"
                      />
                      <div className={styles.menuList}>
                        <div className={styles.labelText}>Menu item</div>
                        <div className={styles.supportingText88}>
                          Supporting text
                        </div>
                      </div>
                      <img
                        className={styles.leadingElementIcon}
                        alt=""
                        src="/trailing-element1.svg"
                      />
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider169} />
                    </div>
                  </div>
                </div>
                <div className={styles.scrollbar}>
                  <div className={styles.scrollbar13} />
                </div>
              </div>
            </div>
            <div className={styles.label2}>Component examples</div>
          </div>
          <div className={styles.buildingBlocks1}>
            <div className={styles.label1}>Building Blocks</div>
            <div className={styles.container9}>
              <div className={styles.buildingBlocksmenuListItem}>
                <div className={styles.stateenabled}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.stateselected}>
                  <div className={styles.stateLayer99}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.statedisabled}>
                  <div className={styles.stateLayer80}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.statehovered}>
                  <div className={styles.stateLayer171}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
              </div>
              <div className={styles.buildingBlocksmenuListItem}>
                <div className={styles.stateenabled}>
                  <div className={styles.stateLayer12}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.stateselected}>
                  <div className={styles.stateLayer111}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.statedisabled}>
                  <div className={styles.stateLayer84}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.statehovered}>
                  <div className={styles.stateLayer175}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
              </div>
              <div className={styles.buildingBlocksmenuListItem}>
                <div className={styles.stateenabled}>
                  <div className={styles.stateLayer24}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.stateselected}>
                  <div className={styles.stateLayer123}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.statedisabled}>
                  <div className={styles.stateLayer88}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
                <div className={styles.statehovered}>
                  <div className={styles.stateLayer179}>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/leading-element1.svg"
                    />
                    <div className={styles.menuList}>
                      <div className={styles.labelText}>Menu item</div>
                      <div className={styles.supportingText88}>
                        Supporting text
                      </div>
                    </div>
                    <img
                      className={styles.icon}
                      alt=""
                      src="/trailing-element1.svg"
                    />
                  </div>
                  <div className={styles.divider144}>
                    <div className={styles.divider169} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.elements1}>
              <div className={styles.buildingBlocksleadingElemen4}>
                <img className={styles.typeicon} alt="" src="/typeicon2.svg" />
                <div className={styles.typeindent} />
              </div>
              <div className={styles.buildingBlockstrailingEleme}>
                <img className={styles.typeicon} alt="" src="/typeicon3.svg" />
                <div className={styles.typetext}>
                  <div className={styles.trailingText}>⌘C</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
