import { FunctionComponent } from "react";
import styles from "./SegmentedButtons.module.css";
const SegmentedButtons: FunctionComponent = () => {
  return (
    <div className={styles.segmentedButtons}>
      <div className={styles.header}>
        <img className={styles.logoIcon} alt="" src="/logo4.svg" />
        <div className={styles.title}>
          <div className={styles.componentName}>Segmented button: outlined</div>
          <a
            className={styles.link}
            href="https://m3.material.io/components/segmented-buttons/overview"
            target="_blank"
          >
            See design guideline
          </a>
          <div
            className={styles.definition}
          >{`Segmented buttons help people select options, switch views, and sort elements. `}</div>
        </div>
      </div>
      <div className={styles.components}>
        <div className={styles.lightScheme}>
          <div className={styles.segmentedButton}>
            <div className={styles.segments5Density3}>
              <div className={styles.segmentStart}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment4}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments5Density2}>
              <div className={styles.segmentStart1}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment4}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments5Density1}>
              <div className={styles.segmentStart2}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment4}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density3}>
              <div className={styles.segmentStart}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density2}>
              <div className={styles.segmentStart1}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density1}>
              <div className={styles.segmentStart2}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density3}>
              <div className={styles.segmentStart}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density2}>
              <div className={styles.segmentStart1}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density1}>
              <div className={styles.segmentStart2}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density1}>
              <div className={styles.segmentStart9}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd9}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density2}>
              <div className={styles.segmentStart10}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd10}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density3}>
              <div className={styles.segmentStart11}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd11}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments5Density0}>
              <div className={styles.segmentStart12}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment4}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density0}>
              <div className={styles.segmentStart12}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density0}>
              <div className={styles.segmentStart12}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density0}>
              <div className={styles.segmentStart15}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd15}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buildingBlocks}>
            <div className={styles.title1}>
              <div className={styles.buildingBlocks1}>Building Blocks</div>
            </div>
            <div className={styles.buildingBlocksbuttonSegment}>
              <div className={styles.statedisabledConfiguration}>
                <div className={styles.container56}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon15.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration1}>
                <div className={styles.container57}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon16.svg"
                    />
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration2}>
                <div className={styles.container57}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer59}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl1}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer59}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi1}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl2}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl3}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer59}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl1}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer59}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi1}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl2}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl3}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer71}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl1}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer71}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi1}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl2}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl3}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi}>
                <div className={styles.container77}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl1}>
                <div className={styles.container1}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi1}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl2}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl3}>
                <div className={styles.container62}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buildingBlocksbuttonSegment1}>
              <div className={styles.statedisabledConfiguration3}>
                <div className={styles.container83}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon15.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration4}>
                <div className={styles.container84}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration5}>
                <div className={styles.container84}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon16.svg"
                    />
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi2}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer59}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl4}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer59}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl5}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl6}>
                <div className={styles.container}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi2}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer59}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl4}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer59}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl5}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl6}>
                <div className={styles.container}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi2}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer71}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl4}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer71}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl5}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl6}>
                <div className={styles.container}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi2}>
                <div className={styles.container104}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl4}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl5}>
                <div className={styles.container86}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi3}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl6}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl7}>
                <div className={styles.container}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buildingBlocksbuttonSegment2}>
              <div className={styles.statedisabledConfiguration6}>
                <div className={styles.container110}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon15.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration7}>
                <div className={styles.container111}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration8}>
                <div className={styles.container111}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon16.svg"
                    />
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi4}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer59}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl8}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer59}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl9}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi5}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl10}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl3}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi4}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer59}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl8}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer59}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl9}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi5}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl10}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl3}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer60}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi4}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer71}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl8}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer71}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl9}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi5}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl10}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl3}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer72}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi4}>
                <div className={styles.container131}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon17.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl8}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl9}>
                <div className={styles.container4}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon18.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi5}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon1.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon19.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl10}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl11}>
                <div className={styles.container116}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.darkScheme}>
          <div className={styles.segmentedButtonDark}>
            <div className={styles.segments5Density31}>
              <div className={styles.segmentStart16}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments5Density21}>
              <div className={styles.segmentStart17}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments5Density11}>
              <div className={styles.segmentStart18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density31}>
              <div className={styles.segmentStart16}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density21}>
              <div className={styles.segmentStart17}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density11}>
              <div className={styles.segmentStart18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density31}>
              <div className={styles.segmentStart16}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density21}>
              <div className={styles.segmentStart17}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment21}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density11}>
              <div className={styles.segmentStart18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment22}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density11}>
              <div className={styles.segmentStart9}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd25}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density21}>
              <div className={styles.segmentStart10}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd26}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density31}>
              <div className={styles.segmentStart11}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd27}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments5Density01}>
              <div className={styles.segmentStart28}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments4Density01}>
              <div className={styles.segmentStart28}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments3Density01}>
              <div className={styles.segmentStart28}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segment29}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.segments2Density01}>
              <div className={styles.segmentStart15}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.segmentEnd31}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buildingBlocks2}>
            <div className={styles.title2}>
              <div className={styles.buildingBlocks1}>Building Blocks</div>
            </div>
            <div className={styles.buildingBlocksbuttonSegment3}>
              <div className={styles.statedisabledConfiguration9}>
                <div className={styles.container193}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon20.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration10}>
                <div className={styles.container194}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration11}>
                <div className={styles.container194}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon21.svg"
                    />
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer196}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer196}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl1}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi1}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl14}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl15}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer196}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer196}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl1}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi1}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl14}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl15}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer208}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer208}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl1}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi1}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl14}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl15}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi6}>
                <div className={styles.container214}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl12}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl13}>
                <div className={styles.container138}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi7}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl14}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl15}>
                <div className={styles.container199}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buildingBlocksbuttonSegment4}>
              <div className={styles.statedisabledConfiguration12}>
                <div className={styles.container220}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon20.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration13}>
                <div className={styles.container221}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration14}>
                <div className={styles.container221}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon21.svg"
                    />
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi8}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer196}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl16}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer196}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl17}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi9}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl15}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi8}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer196}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl16}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer196}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl17}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi9}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl15}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi8}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer208}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl16}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer208}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl17}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi9}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl15}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi8}>
                <div className={styles.container241}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl16}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl17}>
                <div className={styles.container223}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi9}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl18}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl19}>
                <div className={styles.container137}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buildingBlocksbuttonSegment5}>
              <div className={styles.statedisabledConfiguration15}>
                <div className={styles.container247}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon20.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration16}>
                <div className={styles.container248}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statedisabledConfiguration17}>
                <div className={styles.container248}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon21.svg"
                    />
                    <div className={styles.labelText56}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi4}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer196}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl8}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer196}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl9}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationi5}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl22}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statepressedConfigurationl15}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi4}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer196}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl8}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer196}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl9}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationi5}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl22}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statefocusedConfigurationl15}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer198}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi4}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer208}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl8}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer208}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl9}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationi5}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl22}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.statehoveredConfigurationl15}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer210}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi10}>
                <div className={styles.container268}>
                  <div className={styles.stateLayer1}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon22.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl20}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer1}>
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl21}>
                <div className={styles.container141}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon23.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationi11}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon3.svg"
                    />
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/icon24.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl22}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
              <div className={styles.stateenabledConfigurationl23}>
                <div className={styles.container253}>
                  <div className={styles.stateLayer}>
                    <img
                      className={styles.selectedIcon}
                      alt=""
                      src="/selected-icon2.svg"
                    />
                    <div className={styles.labelText}>Label</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentedButtons;
